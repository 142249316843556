import React, { useState } from 'react';
import '../css/home.css'; // 引入CSS文件
import { useNavigate } from 'react-router-dom'; // 引入useNavigate钩子

// Import 所有图片资源
//第一部分
import backgroundImage from '../image/home/背景底图.png';
import daf from '../image/home/daf.png';
import connectButton from '../image/home/connect按钮.png';
import connectStopButton from '../image/home/connect悬停.png';
import lineImage from '../image/home/直线 8.png';
//第二部分
import lineImage1 from '../image/home/直线 1.png';
import lineImage2 from '../image/home/直线 2.png';
import largeImage from '../image/home/4155115.png';
import component1 from '../image/home/组件 6 – 1.png';
import component2 from '../image/home/组件 3 – 1.png';
import mintButton from '../image/home/mint按钮.png';
import mintStopButton from '../image/home/mint按钮悬停.png';


//第三部分
import lineImage8 from '../image/home/直线 8.png';
import leftBackground from '../image/home/组件 2 – 1.png';
import image35155 from '../image/home/35155.png';
import rightBackground from '../image/home/组件 2 – 2.png';
import image41555 from '../image/home/41555.png';

//第四部分
import xlogo from '../image/home/xlogo.png';
import telegramLogo from '../image/home/电报logo.png';


const HomePage = () => {
  const [isHovering, setIsHovering] = useState(false); // 添加状态
  const [isHovering1, setIsHovering1] = useState(false); // 添加状态
  const navigate = useNavigate(); // 声明useNavigate钩子

  const handleHomeClick = () => {
    navigate('/'); // 点击Blog按钮时跳转到Launch页面
  };
  const handleBlogClick = () => {
    navigate('/launch'); // 点击Blog按钮时跳转到Launch页面
  };
  const handleContactClick = () => {
    navigate('/rank'); // 点击Blog按钮时跳转到Launch页面
  };

  return (
    <div className="home-container1" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* 透明图片叠加层 */}
      <div className="overlay1"></div>
      {/* 第一排左边图片 */}
      <div className="first-row">
        <img src={daf} alt="daf" className="left-image" />

        {/* 第一排右边部分 */}
        <div className="right-section">
          <div className="button-row">
            <div className="button1">OpenBoxNFT</div>
            {/* //home */}
            <div className="button" onClick={handleHomeClick}>Home</div>
            <div className="button" onClick={handleBlogClick}>Launch</div>
            <div className="button" onClick={handleContactClick}>Ranking</div>
            <img 
            src={isHovering1 ? connectStopButton : connectButton} // 根据isHovering状态切换图片
            alt="Connect" 
            className="connect-button"
            onMouseEnter={() => setIsHovering1(true)} // 鼠标进入时设置isHovering为true
            onMouseLeave={() => setIsHovering1(false)} // 鼠标离开时设置isHovering为false
            />
          </div>

          {/* 底部的直线 */}
          <div className="line-row">
            <img src={lineImage} alt="Line" className="line-image" />
          </div>
        </div>
      </div>
       {/* 左右分栏 */}
       <div className="main-content">
        {/* 左边部分 */}
        <div className="left-side">
          <div className="left-top">
            <div className="left-image1">OPENBOX&nbsp;MINT</div>
            {/* <img src={lineImage1} alt="Line" className="line-image" /> */}
          </div>
          <div className="left-middle">
            <img src={largeImage} alt="Large" className="large-image" />
          </div>
          <div className="left-bottom">
            <div className="bottom-row">
              <div className="bottom-image">PRICE
              <font className="other"><br></br>0.15&nbsp;SOL</font></div>
              <div className="bottom-image">SUPPLY
              <font className="other"><br></br>3888</font></div>
              <div className="bottom-image1">ACCOUNT&nbsp;LIMIT
              <font className="other"><br></br>20&nbsp;Per&nbsp;Wallet</font>
              </div>
            </div>
            {/* <img src={lineImage1} alt="Line" className="line-image1" /> */}
          </div>
        </div>

        {/* 右边部分 */}
        <div className="right-side">
          <div className="right-top">
            <div className="right-image">MINT&nbsp;PHASES</div>
            <img src={component1} alt="Component 1" className="component-image" />
          </div>
          <img src={lineImage2} alt="Line" className="line-image" />
          <div className="right-middle">
            <div className="component-background">
              <img src={component2} alt="Component 2" className="component-background-image" />
              <div className="component-content">
              <div className="component-item">SUPPLY:<font className="right">3888</font></div>
              <div className="component-item item-2">MINTED:<font className="right">3888</font></div>
              <div className="component-item item-1">YOU&nbsp;HAVE:<font className="right1">3888</font></div>
              </div>
            </div>
            <img  src={isHovering ? mintStopButton : mintButton} // 根据isHovering状态切换图片
            alt="Mint Button" 
            className="mint-button"
            onMouseEnter={() => setIsHovering(true)} // 鼠标进入时设置isHovering为true
            onMouseLeave={() => setIsHovering(false)} // 鼠标离开时设置isHovering为false
            />
          </div>
          <img src={lineImage2} alt="Line" className="line-image3" />
        </div>
        </div>
        <div className="content-container">
      {/* 第一模块 */}
      <div className="description-section">
        <div className="description-title">DESCRIPTION</div>
        <img src={lineImage8} className="line-image" alt="Line" />
        <div className="description-content">The OpenBox NFT is an NFT minted publicly by the OpenBox platform. After minting, holders will randomly receive either a Premium Pass or a Standard Pass. Holding an OpenBox NFT will entitle the holder to an airdrop of OPBOX tokens. The number of OPBOX tokens airdropped will vary based on the NFT tier. These tokens will serve as platform tokens with added utility.Additionally, holders of Premium Passes will be granted more privileges in the future.</div>
      </div>

      {/* 第二模块 */}
      <div className="tiers-section">
        {/* <img src={tiersTitle} className="tiers-title" alt="NFT Tiers" /> */}
        <div className="tiers-title">NFT&nbsp;Tiers</div>
        <img src={lineImage8} className="line-image4" alt="Line" />

        <div className="tiers-content">
          {/* 左边部分 */}
          <div className="left-tier">
            <img src={leftBackground} className="left-background" alt="Left Background" />
            <img src={image41555} className="left-overlap" alt="Overlap" />
            <div className="left-details">
              <div className="tier-label">Standard&nbsp;Pass</div>
              {/* <img src={standardPass} className="tier-label" alt="Standard Pass" /> */}
              <div className="tier-supply">Supply:&nbsp;3500</div>
              {/* <img src={supply3333} className="tier-supply" alt="Supply 3333" /> */}
              <div className="tier-token">$OPBOX&nbsp;Token&nbsp;Quantity:&nbsp;1,000,000</div>
              {/* <img src={token1000000} className="tier-token" alt="Token Quantity 1,000,000" /> */}
            </div>
          </div>

          {/* 右边部分 */}
          <div className="right-tier">
            <img src={rightBackground} className="right-background" alt="Right Background" />
            <img src={image35155} className="right-overlap" alt="Overlap" />
            <div className="right-details">
            <div className="tier-label">Premium&nbsp;Pass</div>
              {/* <img src={standardPass} className="tier-label" alt="Standard Pass" /> */}
              <div className="tier-supply">Supply:&nbsp;388</div>
              {/* <img src={supply555} className="tier-supply" alt="Supply 555" /> */}
              <div className="tier-token">$OPBOX&nbsp;Token&nbsp;Quantity:&nbsp;1,500,000</div>
              {/* <img src={token1500000} className="tier-token" alt="Token Quantity 1,500,000" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-icons">
  <img src={xlogo} className="footer-icon" alt="xlogo" />
  <img src={telegramLogo} className="footer-icon" alt="Telegram" />
</div>
</div>
  );
};

export default HomePage;
