import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './component/HomeComponent'; // 假设HomePage组件在pages文件夹中
import LaunchComponent from './component/LaunchComponent'; // 引入LaunchComponent组件
import RankComponent from './component/RankComponent';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} /> {/* 默认首页 */}
        <Route path="/launch" element={<LaunchComponent />} /> {/* Launch 页面 */}
        <Route path="/rank" element={<RankComponent />} /> {/* Rank 页面 */}
      </Routes>
    </Router>
  );
};

export default App;
