import React, { useState } from 'react';
import '../css/rank.css'; // 引入CSS文件
import { useNavigate } from 'react-router-dom'; // 引入useNavigate钩子

// Import 所有图片资源
//第一部分
import backgroundImage from '../image/ranking/背景.png';
import daf from '../image/ranking/daf.png';
import connectButton from '../image/ranking/组件 4 – 3.png';
import lineImage from '../image/launch/直线 5.png';

// import launchingSoon from '../image/ranking/Launching soon.png';
import component15 from '../image/ranking/组件 15 – 1.png';
// import marketCapRanking from '../image/ranking/Market Cap Ranking.png';
import component16 from '../image/ranking/组件 16 – 1.png';
import component12 from '../image/ranking/组件 12 – 1.png';
import connectStopButton from '../image/home/connect悬停.png';

//第四部分
import xlogo from '../image/home/xlogo.png';
import telegramLogo from '../image/home/电报logo.png';


const LaunchPage = () => {
  const [isHovering1, setIsHovering1] = useState(false); // 添加状态
  const navigate = useNavigate(); // 声明useNavigate钩子

  const handleHomeClick = () => {
    navigate('/'); // 点击Blog按钮时跳转到Launch页面
  };
  const handleBlogClick = () => {
    navigate('/launch'); // 点击Blog按钮时跳转到Launch页面
  };
  const handleContactClick = () => {
    navigate('/rank'); // 点击Blog按钮时跳转到Launch页面
  };
  return (
    <div className="home-container2" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* 透明图片叠加层 */}
      <div className="overlay2"></div>
      {/* 第一排左边图片 */}
      <div className="first-row">
        <img src={daf} alt="daf" className="left-image" />

        {/* 第一排右边部分 */}
        <div className="right-section">
          <div className="button-row">
          <div className="button1">OpenBoxNFT</div>
            {/* //home */}
            <div className="button" onClick={handleHomeClick}>Home</div>
            <div className="button" onClick={handleBlogClick}>Launch</div>
            <div className="button" onClick={handleContactClick}>Ranking</div>
            <img 
            src={isHovering1 ? connectStopButton : connectButton} // 根据isHovering状态切换图片
            alt="Connect" 
            className="connect-button"
            onMouseEnter={() => setIsHovering1(true)} // 鼠标进入时设置isHovering为true
            onMouseLeave={() => setIsHovering1(false)} // 鼠标离开时设置isHovering为false
            />
          </div>

          {/* 底部的直线 */}
          <div className="line-row">
            <img src={lineImage} alt="Line" className="line-image" />
          </div>
        </div>
      </div>
    
     {/* 左右两部分布局 */}
     <div className="content-row">
        {/* 左边部分 */}
        <div className="left-column">
        <div className="left-image-top">Launching Soon</div>
          {/* <img src={launchingSoon} alt="Launching Soon" className="left-image-top" /> */}
          <img src={component15} alt="Component 15" className="left-image-bottom" />
        </div>

        {/* 右边部分 */}
        <div className="right-column">
        <div className="right-image-top">Market Cap Ranking</div>
          {/* <img src={marketCapRanking} alt="Market Cap Ranking" className="right-image-top" /> */}
          <img src={component16} alt="Component 16" className="right-image-bottom" />
        </div>
      </div>

      {/* 悬浮图片 */}
      <div className="floating-image">
        <img src={component12} alt="Floating Component" className="floating-image-content" />
      </div>

     {/* Footer 图标 */}
    <div className="footer-icons">
  <img src={xlogo} className="footer-icon" alt="xlogo" />
  <img src={telegramLogo} className="footer-icon" alt="Telegram" />
</div>
</div>
  );
};

export default LaunchPage;
