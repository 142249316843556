import React, { useState } from 'react';
import '../css/launch.css'; // 引入CSS文件
import { useNavigate } from 'react-router-dom'; // 引入useNavigate钩子

// Import 所有图片资源
//第一部分
import backgroundImage from '../image/launch/Web 1920 – 2.png';
import daf from '../image/launch/daf.png';
import connectButton from '../image/launch/组件 4 – 2.png';
import lineImage from '../image/launch/直线 5.png';
import connectStopButton from '../image/home/connect悬停.png';

// 中间的叠加图片
import component9 from '../image/launch/组件 9 – 1.png';
import component10 from '../image/launch/组件 10 – 1.png';

//第四部分
import xlogo from '../image/home/xlogo.png';
import telegramLogo from '../image/home/电报logo.png';


const LaunchPage = () => {
  const [isHovering1, setIsHovering1] = useState(false); // 添加状态
  const navigate = useNavigate(); // 声明useNavigate钩子

  const handleHomeClick = () => {
    navigate('/'); // 点击Blog按钮时跳转到Launch页面
  };
  const handleBlogClick = () => {
    navigate('/launch'); // 点击Blog按钮时跳转到Launch页面
  };
  const handleContactClick = () => {
    navigate('/rank'); // 点击Contacts按钮时跳转到rank页面
  };
  return (
    <div className="home-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* 透明图片叠加层 */}
      <div className="overlay"></div>
      {/* 第一排左边图片 */}
      <div className="first-row">
        <img src={daf} alt="daf" className="left-image" />

        {/* 第一排右边部分 */}
        <div className="right-section">
          <div className="button-row">
          <div className="button1">OpenBoxNFT</div>
            {/* //home */}
            <div className="button" onClick={handleHomeClick}>Home</div>
            <div className="button" onClick={handleBlogClick}>Launch</div>
            <div className="button" onClick={handleContactClick}>Ranking</div>
            <img 
            src={isHovering1 ? connectStopButton : connectButton} // 根据isHovering状态切换图片
            alt="Connect" 
            className="connect-button"
            onMouseEnter={() => setIsHovering1(true)} // 鼠标进入时设置isHovering为true
            onMouseLeave={() => setIsHovering1(false)} // 鼠标离开时设置isHovering为false
            />
          </div>

          {/* 底部的直线 */}
          <div className="line-row">
            <img src={lineImage} alt="Line" className="line-image" />
          </div>
        </div>
      </div>

       {/* 中间的叠加组件 */}
       <div className="middle-overlay">
        <img src={component9} alt="Component 9" className="component9" />
        <img src={component10} alt="Component 10" className="component10" />
      </div>

     {/* Footer 图标 */}
    <div className="footer-icons">
  <img src={xlogo} className="footer-icon" alt="xlogo" />
  <img src={telegramLogo} className="footer-icon" alt="Telegram" />
</div>
</div>
  );
};

export default LaunchPage;
